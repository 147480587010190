import Vue from 'vue'
import App from './App.vue'
import VueAnalytics from 'vue-analytics'
import './styles/reset.css'
import './styles/layout.css'
Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'G-29WZWCBM4H'
})

new Vue({
  render: h => h(App),
}).$mount('#app')
