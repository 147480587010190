<template>
  <div class="content" @scroll="onScroll">
    <div class="diff-tip" v-if="diff.length">
      <span class="msg" @click="manage('read')"
        >有{{ diff.length }}个新话题，点击查看</span
      >
      <span class="close" @click="manage('close')">×</span>
    </div>
    <div class="content-item" v-for="(item, index) in list" :key="index">
      <div class="header" @click="toggleContent(item)">
        <span class="title">{{ item.title }}</span>
        <span class="time">{{ formatTime(item.publishDate) }}</span>
        <span
          class="inst-view"
          title="查看详情"
          @click="openUrl(item.url)"
        ></span>
      </div>
      <div class="timeline"></div>
      <div :class="{ summary: true, 'show-summary': item.show }">
        {{ item.summary }}
      </div>
    </div>
  </div>
</template>
<script>
import { formatTime } from "@/utils/format";
export default {
  props: {
    list: Array,
    diff: Array,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onScroll() {
      const { clientHeight, scrollTop, scrollHeight } = document.querySelector(
        ".content"
      );
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("bottom !");
      }
    },
    toggleContent(item) {
      item.show = !item.show;
      this.$forceUpdate();
    },
    openUrl(url) {
      window.open(url, "_blank", "noopener");
    },
    manage(type) {
      this.$emit("manage", type);
    },
    addNews() {
      this.newsClass = "news";
    },
    clearNews() {
      this.newsClass = "";
    },
    formatTime,
  },
};
</script>
<style scoped lang="scss">
.content {
  box-sizing: border-box;
  padding: 10px;
  height: calc(100vh - 55px);
  max-height: 900px;
  overflow-y: auto;
  padding-left: 20px;
  .diff-tip {
    width: 80%;
    margin: 10px auto;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid #dedacf;
    border-radius: 3px;
    background-color: #fff7e0;
    box-shadow: 4px 4px 0px 0px #d3d3d394;
    color: #7a6c5d;
    span.msg {
      cursor: pointer;
    }
    span.close {
      float: right;
      cursor: pointer;
    }
  }
  .content-item {
    padding: 20px 0px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
    padding: 0 0 30px 30px;
    border-left: 1px solid #dcdcdc;
    .header {
      .title {
        font-size: 18px;
        color: #000;
        margin-right: 18px;
        letter-spacing: 2px;
        cursor: pointer;
      }
      .time {
        text-indent: 0;
        font-size: 14px;
        font-family: PingFangSC-Light, Microsoft YaHei UI Light,
          Noto Sans CJK SC Light, sans-serif;
        color: #999;
        display: inline-block;
      }
      .inst-view {
        display: inline-block;
        border: 1px solid #a9bfd5;
        border-radius: 3px;
        width: 42px;
        height: 19px;
        line-height: 16px;
        vertical-align: text-bottom;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        margin-left: 10px;
        &:hover {
          cursor: pointer;
          background-color: #edf1f5;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-image: url(../assets/sprite.png);
          background-position: -399px -532px;
          width: 42px;
          height: 18px;
          transform: scale(0.5) translate(18px, 8px);
          transform-origin: 0 0;
        }
        &::after {
          content: "";
          width: 16px;
          height: 17px;
          position: absolute;
          top: 0;
          left: -20px;
          transform: skewX(-54deg);
          background-color: #fff;
          animation: blingbling 0.5s 0.3s;
          animation-delay: 0.6s;
        }
      }
    }
    .timeline {
      width: 9px;
      height: 9px;
      background: #f29700;
      border-radius: 100%;
      position: absolute;
      left: -5px;
      top: 3px;
    }
    .summary {
      max-height: 0px;
      overflow-y: hidden;
      visibility: hidden;
      letter-spacing: 2px;
      text-align: justify;
      color: rgb(118, 118, 128);
      transition: max-height 1s linear, visibility 1s linear;
    }
    .show-summary {
      max-height: 500px;
      visibility: visible;
    }
  }
}
@keyframes blingbling {
  0% {
    left: -20px;
  }

  to {
    left: 45px;
  }
}
</style>