<template>
  <div id="app">
    <Head />
    <Loading style="height: 300px;" v-if="!dataList.length"/>
    <Content :list="dataList" :diff="diff" @manage="manage"/>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Content from "@/components/Content";
import Loading from "@/components/Loading";
export default {
  name: "App",
  data() {
    return {
      dataList: [],
      newList: [],
      type: 'blockchain',
      stop: false,
      diff: [],
      // baseApi: 'http://118.25.80.80:9539/proxy/',
      baseDomainApi: '//proxy.ioplus.tech/proxy/'
    };
  },
  components: {
    Head,
    Content,
    Loading
  },
  methods: {
    manage (type) {
      this.diff = []
      if (type === 'read') {
        this.newList.length && (this.dataList = this.newList)
      }
      console.log(this.dataList)
    },
    showAlert (list, diff) {
      this.diff = diff
      this.newList = list
    },
    getPosts() {
      return fetch(this.baseDomainApi + `https://api.readhub.cn/${this.type}`).then((res) => res.json());
    },
    diffArray (oldArray, newArray){
      if (oldArray.map(a => a.title).join(',') === newArray.map(a => a.title).join(',')) return false
      return newArray.filter(item => !oldArray.some(oldItem => oldItem.title === item.title))
    },
    timerFetch (time = 1000) {
      this.timer = setTimeout(() => {
        this.getPosts()
          .then(res => {
            if (res && res.data) {
              let diff = this.diffArray(this.dataList, res.data)
              if (diff && diff.length) {
                this.showAlert(res.data, diff)
              }
            }
          })
          .finally(() => {
            if (!this.stop)
            this.timerFetch(time)
          })
      }, time)
    }
  },
  async created() {
    const res = await this.getPosts()
    if (res && res.data) {
      this.dataList = res.data
    }
    this.timerFetch(10 * 1e3)
  },
};
</script>

<style>
#app {
  margin: auto;
  border-radius: 5px;
  max-width: 800px;
  max-height: 960px;
  background-color: white;
  color:#737373;
  line-height: 20px;
  border: solid 1px lightgrey;
}
</style>
