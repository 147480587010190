<template>
  <div class="loading">
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
  </div>
</template>
<script>
export default {
  props: {
    list: Array,
    diff: Array
  },
  data() {
    return {};
  },
  created() {},
  methods: {
  },
};
</script>
<style scoped lang="scss">
$loading-gap: 5px;
$loading-width: 5px;
$loading-height: 60px;
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100px;
  &-dot{
    height: 0px;
    width: $loading-width;
    margin-left: $loading-gap;
    margin-right: $loading-gap;
    border-radius: 3px;
    background-color: lightgrey;
    &:nth-child(1){
      animation: loading 1s 0s infinite alternate;
    }
    &:nth-child(2){
      animation: loading 1s 0.1s infinite alternate;
    }
    &:nth-child(3){
      animation: loading 1s 0.2s infinite alternate;
    }
    &:nth-child(4){
      animation: loading 1s 0.3s infinite alternate;
    }
    &:nth-child(5){
      animation: loading 1s 0.4s infinite alternate;
    }
  }
}
@keyframes loading{
  0% {
    height: 10px;
  }
  100% {
    height: $loading-height;
  }
}
</style>