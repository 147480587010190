<template>
  <div class="header">
    <div class="title">
      <img alt="cryptonews" src="../assets/small-logo.png" />
      <!-- <span>{{ titlePrefix }}</span>
      <span class="append">{{ titleAppend }}</span> -->
    </div>
    <div class="user" @click="toggleNews">
      <span class="user-info">
        <img src="../assets/avatar.svg" />
        <span :class="newsClass"></span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      titlePrefix: "Coin",
      titleAppend: "Market",
      newsClass: ''
    };
  },
  created() {
    setTimeout(() => {
      this.addNews()
      setTimeout(this.clearNews, 2000)
    }, 2000)
  },
  methods: {
    toggleNews () {
      this.newsClass = this.newsClass ? '' : 'news'
    },
    addNews () {
      this.newsClass = 'news'
    },
    clearNews() {
      this.newsClass = ''
    }
  }
};
</script>
<style scoped lang="scss">
$title-height: 50px;
.header {
  height: $title-height;
  line-height: $title-height;
  display: flex;
  padding: 0px 10px;
  justify-content: space-between;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      height: 30px;
      border-radius: 5px;
      margin-right: 6px;
      transition: all 0.1s linear;
      &:hover{
        transform: scale(1.01);
      }
    }
    span {
      font-size: 1.3em;
      font-weight: bold;
      color: rgb(182, 182, 182);
    }
    .append {
      color: rgb(212, 54, 54);
      font-size: 1.2em;
    }
  }
  .user {
    display: flex;
    align-items: center;
    cursor: pointer;
    span.user-info {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      // border: solid 2px lightgrey;
      border-radius: 8px;
      height: 40px;
      width: 40px;
      // overflow: hidden;
      transition: border-color 0.1s linear;
      &:hover{
        border-color: grey;
      }
      img {
        height: 35px;
        width: 35px;
        transition: all 0.1s linear;
      }
      .news{
        position: absolute;
        top: 0px;
        right: 0px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color:rgb(212, 54, 54);
        transition: box-shadow 0.3s linear;
        box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 0.269);
      }
      &:hover .news{
        box-shadow: 0px 0px 4px 4px rgba(255, 0, 0, 0.269);
      }
      &:hover img{
        transform: scale(1.05);
      }
    }
  }
  box-shadow: 0px 4px 5px rgba(211, 211, 211, 0.331);
}
</style>